import axios, { AxiosResponse } from 'axios';
import { initializeApp, getApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { Conversation } from '../server/src/chat';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { AppConversation } from './App';

//const REGION = 'asia-northeast1';

// development 開発時に production を使うか staging を使うか
const DEV_MODE = 'production';

export const MOCK_AUTH = process.env.REACT_APP_MOCK_AUTH === '1';

export const PRODUCTION =
  process.env.NODE_ENV === "development" ?
  DEV_MODE === "production" :
  process.env.REACT_APP_PRODUCTION === "1";

const firebaseConfig = PRODUCTION ? 
  {
    apiKey: "AIzaSyAyX3d9jCNoPMSI6NoWoenMCkApbAs1TcY",
    authDomain: "chat-recipe.firebaseapp.com",
    projectId: "chat-recipe",
    storageBucket: "chat-recipe.appspot.com",
    messagingSenderId: "58650892258",
    appId: "1:58650892258:web:519fdbdfcef3394bcecbbb",
    measurementId: "G-HGFJMW3RKN"
  }
  :
  {
    apiKey: "AIzaSyChdd2s3JOYW_H3TaNF240PehMlMXPt8zQ",
    authDomain: "chat-recipe-staging.firebaseapp.com",
    projectId: "chat-recipe-staging",
    storageBucket: "chat-recipe-staging.appspot.com",
    messagingSenderId: "913922192285",
    appId: "1:913922192285:web:57ab4c5c1f7546422f7574",
    measurementId: "G-91QSELC1BD"
  };

initializeApp(firebaseConfig);
const functions = getFunctions(getApp(), 'asia-northeast1');

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectAuthEmulator(getAuth(), "http://localhost:9099");
  connectFirestoreEmulator(getFirestore(), "localhost", 8080);
}

//const app = initializeApp();

//export const functions = getFunctions(app);


export const AUTH_URL = PRODUCTION ? "https://id.zaim.net" : 
  "https://id.stg-zaim.net";

export const AUTH_CLIENT_ID = PRODUCTION ?
  '98f54fe2-4182-4a38-9e3b-aa97e9a9e535' :
  '98f55114-b152-4711-b7d0-ce7f7fb40800'; // staging

export const CALLBACK_URI = PRODUCTION ?
  'https://ai-recipe.zaim.net/' :
  'https://recipe.stg-zaim.net/';

const REGION = 'asia-northeast1';


export const functionURL = (name: string) => {
  return process.env.NODE_ENV === 'development' ?
    `http://${window.location.hostname}:5001/${firebaseConfig.projectId}/${REGION}/${name}` :
    `https://${REGION}-${firebaseConfig.projectId}.cloudfunctions.net/${name}`;
}

interface CompletionListener {
  update(message: string): void;
  done(): void;
  aborted(err: any): void;
}

const createCompletionConfig = () => {
  if (process.env.NODE_ENV === "production" && PRODUCTION) {
    return {
      url: "https://ai-recipe-api.zaim.net/createCompletion",
      content_type: 'application/json'
    };
  } else {
    return {
      url: functionURL('createCompletion'),
      content_type: 'text/plain'
    };
  }
}


export const createCompletion = (conversation: Conversation, listener: CompletionListener) => {
  getAuth().currentUser!.getIdToken().then(token => {
    fetch(createCompletionConfig().url, {
      method: 'POST',
      headers: {
        'Content-Type': createCompletionConfig().content_type,
        /*'Authorization': `Bearer ${token}`*/
      },
      body: JSON.stringify({conversation, token})
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const reader = response.body!.getReader();
      return new ReadableStream({
        start(controller) {
          function push() {
            reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }
              controller.enqueue(value);
              push();
            });
          }
          push();
        }
      });
    })
    .then(stream => {
      const reader = stream.getReader();
      const decoder = new TextDecoder();
      let message = '';
      function read() {
        reader.read().then(({ done, value }) => {
          if (done) {
            listener.done();
            return;
          }
          message += decoder.decode(value);
          //console.log(decoder.decode(value));
          listener.update(message);
          read();
        });
      }
      read();
    })
    .catch(error => {
      console.error(error);
      listener.aborted(error);
    });
  }).catch(err => {
    console.error(err);
  });
}

type Item = {
  name: string;
}

export const getRecentItems = async (): Promise<{ items: Item[], familyMembers: number, preset: boolean }> => {
  const defaultItems = [
    '鶏もも肉', 'ナス', 'ピーマン', 'セロリ', 'トマト', 'じゃがいも', '人参', '牛バラ肉'
  ];
  const familyMembersById = [
    0,
    1, 2, 2, 3,
    4, 5, 4, 5,
    6, 7, 2, 4
  ];
  let recentItems: Item[] = [];
  let familyMembers = 4;
  if (!MOCK_AUTH) {
    const data = (await httpsCallable(functions, 'getRecentItems')()).data as any;
    // console.log('getRecentItems data', data);
    recentItems = data.money;
    familyMembers = familyMembersById[data.familyId as number] || 4;
  }
  if (recentItems.length < 3) {
    return { items: defaultItems.map(name => ({name})), familyMembers, preset: true };
  } else {
    return { items: recentItems, familyMembers, preset: false };
  }
};

export const getNumConversationsRestToday = async (): Promise<number> => {
  const data = (await httpsCallable(functions, 'getNumConversationsRestToday')()).data as any;
  return data.num as number;
};

export const registerConversation = async (conversationId: string) => {
  await httpsCallable(functions, 'registerConversation')({
    conversationId
  });
};

export const saveConversation = async (conversation: AppConversation) => {
  const { id, ...storedConv } = conversation;
  await httpsCallable(functions, 'saveConversation')({
    id, conversation: storedConv
  });
}
